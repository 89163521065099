import React, { useEffect } from 'react';
import FactviewLogo from '../FactviewLogo.png';
import '../css/header.css';
import ProfileComponent from './ProfileComponent.js';
import LanguageComponent from './LanguageComponent.js';
function Header() {
  
  useEffect(() =>{
  }, []); // [] makes sure that this useEffect block only runs on the first render of the component

  return (
      <header className="app-header">
        <div className="app-top-bar">
          <img src={FactviewLogo} className="header-img" alt="logo" />
          <div className="header-title">
            <p>Import Module</p>
          </div>
        </div>
        <div className="language-container">
          {/* <ProfileComponent></ProfileComponent> */}
          <LanguageComponent></LanguageComponent>
        </div>
      </header >
  );
}

export default Header;
