import React, { useEffect, useState } from 'react';
// Import the core library and icon packs you need
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Add the icons you will use to the library
library.add(faXmark);

function FilterComponent({data, type,setFilterString, setShowFilterInput}) {
  const [filterArray, setFilterArray] = useState([]);
  const [inputString, setInputString] = useState();
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() =>{
    handleFilterArray();
  }, [data, inputString]); // [] makes sure that this useEffect block only runs on the first render of the component
  
  // Creates filter array required for displaying the dropdown options
  async function handleFilterArray(){
    var arr = [];

    for(const filter of data){
      if(arr.indexOf(filter[type]) == -1){
        arr.push(filter[type]);
      }
    }
    setFilterArray(arr);
  }

  // onClick close event for the filter input
  async function handleClose(){
    setFilterString(false);
    setInputString("");
    setShowFilterInput(false);   
  }

  // onClick dropdown select option for the filter
  async function onSelect(string){
    setFilterString(string);
    setInputString(string);
    setShowDropdown(false);
  }

  // onChange input event for rendering the dropdown options
  async function onInput(event){
    setFilterString(event.target.value);
    setInputString(event.target.value);
  }

  // handle onFocus event for the filter input
  async function handleFocus(){
    setShowDropdown(true);
  }

  // handles the unFocus event for the filter input
  async function handleBlur(){
    setTimeout(function(){
      setShowDropdown(false);
    },250);
  }
  
  return (
    <div className="filter-component">
      <div className="filter-input-container">
      <input
        type={(type != "year")?"text":"number"}
        maxLength={(type == "year")?4:undefined}
        className="filter-input"
        onInput={onInput}
        value={inputString}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <FontAwesomeIcon className="filter-close hover" onClick={()=>handleClose()} icon={faXmark}/>
      </div>
      {(showDropdown)?<ul className="ul-filter">
        {(filterArray && filterArray.length>0) ? filterArray.map((filter, index) => (
          <li key={index} className="li-filter hover" onClick={()=>onSelect(filter)}>
            {filter}
          </li>
        )):null}
      </ul>
      :null}
    </div>
  );
}

export default FilterComponent;
