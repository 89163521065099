// src/components/ConfirmationComponent.js
import React from 'react';

const ConfirmationComponent = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="confirmation-dialog">
      <p>{message}</p>
      <button className="btn btn-confirm hover" onClick={() => { onConfirm(); onCancel();}}>Yes</button>
      <button className="btn hover" onClick={onCancel}>No</button>
    </div>
  );
};

export default ConfirmationComponent;
