import {splitArrayIntoChunks, getCookie} from './utils';

export async function executeCaseQuery(case_id, query){
  try { 
    const response = await fetch('https://api2.factview.nl/api/'+case_id+'/executeQuery', {
      method: 'POST',
      headers: {
        'authorization': 'Bearer '+ getCookie("factview_access"),
        'Content-Type': 'application/json',
        'Accept': 'application/json' 
      },
      body: JSON.stringify({
        query: query,
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
    else if (!response.ok) {
      if (response.status === 401) {
        console.error('Error fetching data: Invalid access token'); 
        return false;
      }
    }
  }
  catch (error) {
  console.error('Error fetching data:', error);
  }
}

export async function deleteFromTableWhere(case_id, tablename, where, value){
  var query= "DELETE FROM `" + tablename + "` WHERE `" + where + "` = " + value;
  const result = await executeCaseQuery(case_id, query, getCookie("factview_access"));

  return result;
}

export async function createImp(case_id,  filename, year, product, compid){
  try {
    const response = await fetch('https://api2.factview.nl/api/'+case_id+'/createImp', {
      method: 'POST',
      headers: {
        'authorization': 'Bearer '+ getCookie("factview_access"),
        'Content-Type': 'application/json',
        'Accept': 'application/json' 
      },
      body: JSON.stringify({
        filename: filename,
        fiscalyear: year,
        datecreated: new Date(),
        product: product,
        compid: compid,
        stamp: new Date(),
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function checkImportExact(entiteit, jaar, budget_scenario, office){
  try {
    const response = await fetch('/import_exact/findByEntityYearBudget?entiteit='+entiteit+"&year="+jaar+"&budget_scenario="+budget_scenario);
    if (response.ok) {
      const responseData = await response.json();  
      return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function updateImportExactStamp(id){
  try {
    const response = await fetch('/import_exact/updateStamp?id='+id)
    if (response.ok) {
      const responseData = await response.json();
     return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function updateImportExactImpid(impid, entiteit, jaar, budget_scenario){
  try {
    const response = await fetch('/import_exact/updateImpid', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        impid: impid,
        entiteit: entiteit,
        year: jaar,
        budget_scenario: budget_scenario,
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function createBoekjaar(case_id, year, impid, userId){
  try {
    const response = await fetch('https://api2.factview.nl/api/'+case_id+'/createBoekjaar', {
      method: 'POST',
      headers: {
        'authorization': 'Bearer '+ getCookie("factview_access"),
        'Content-Type': 'application/json',
        'Accept': 'application/json' 
      },
      body: JSON.stringify({
        jaartal: year,
        onderneming: 1,
        gewijzigd: new Date(),
        impid: impid,
        afkomst: '"FROM:"import"',
        notitie: 'Afkomstig van Exactonline import',
        door: userId,
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function createDataset(case_id, name){
  try {
    const response = await fetch('https://api2.factview.nl/api/'+case_id+'/createDataset', {
      method: 'POST',
      headers: {
        'authorization': 'Bearer '+ getCookie("factview_access"),
        'Content-Type': 'application/json',
        'Accept': 'application/json' 
      },
      body: JSON.stringify({
        naam: name,
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
export async function createDatasetjaar(case_id, year, impid, dataset){
  try {
    const response = await fetch('https://api2.factview.nl/api/'+case_id+'/createDatasetjaar', {
      method: 'POST',
      headers: {
        'authorization': 'Bearer '+ getCookie("factview_access"),
        'Content-Type': 'application/json',
        'Accept': 'application/json' 
      },
      body: JSON.stringify({
        jaar: year,
        onderneming: 1,
        dataset: dataset,
        impid: impid,
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}


export async function importTrLine(case_id, array){
  try {
    const arrBody = await splitArrayIntoChunks(array, 100);

    for(const arr of arrBody){
      const response = await fetch('https://api2.factview.nl/api/'+case_id+'/createTrLine', {
        method: 'POST',
        headers: {
          'authorization': 'Bearer '+ getCookie("factview_access"),
          'Content-Type': 'application/json',
          'Accept': 'application/json' 
        },
        body: JSON.stringify(arr),
      })
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function importAccount(case_id, array){
  try {
    const arrBody = await splitArrayIntoChunks(array, 100);

    for(const arr of arrBody){
      const response = await fetch('https://api2.factview.nl/api/'+case_id+'/createAccount', {
        method: 'POST',
        headers: {
          'authorization': 'Bearer '+ getCookie("factview_access"),
          'Content-Type': 'application/json',
          'Accept': 'application/json' 
        },
        body: JSON.stringify(arr),
      })
    }
  }
  catch (error) {
    console.error('Error fetching data:', error);
  }
}
export async function importBasic(case_id, array){
  try {
    const arrBody = await splitArrayIntoChunks(array, 100);

    for(const arr of arrBody){
      const response = await fetch('https://api2.factview.nl/api/'+case_id+'/createBasic', {
        method: 'POST',
        headers: {
          'authorization': 'Bearer '+ getCookie("factview_access"),
          'Content-Type': 'application/json',
          'Accept': 'application/json' 
        },
        body: JSON.stringify(arr),
      })
      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      }
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function importCustomersupplier(case_id, arr){
  try {
    const response = await fetch('https://api2.factview.nl/api/'+case_id+'/createCustomersupplier', {
      method: 'POST',
      headers: {
        'authorization': 'Bearer '+ getCookie("factview_access"),
        'Content-Type': 'application/json',
        'Accept': 'application/json' 
      },
      body: arr,
    })
    if (response.ok) {
      const responseData = await response.json();
     return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function importPeriod(case_id, arr){
  try {
    const response = await fetch('https://api2.factview.nl/api/'+case_id+'/createPeriod', {
      method: 'POST',
      headers: {
        'authorization': 'Bearer '+ getCookie("factview_access"),
        'Content-Type': 'application/json',
        'Accept': 'application/json' 
      },
      body: arr,
    })
    if (response.ok) {
      const responseData = await response.json();
     return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
export async function clearExistingScheduledImports(body){
  try{
    console.log(body);
    const response = await fetch('/scheduler/deleteScheduledImportBy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    if (response.ok) {
      const responseData = await response.json();
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}