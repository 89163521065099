import React, { useEffect } from 'react';
import '../css/footer.css';

function Footer() {
  useEffect(() =>{
  }, []); // [] makes sure that this useEffect block only runs on the first render of the component

  return (
      <footer className="app-footer">
        <div className="app-bottom-bar">
          <div className="footer-title">
            <p>Copyrights © 2024 Factview</p>
          </div>
        </div>
      </footer >
  );
}

export default Footer;
