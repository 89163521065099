import React, { useState, useEffect, useRef } from 'react';
import { useTranslations } from './TranslationContext';
import GB from '../images/flag64/united-kingdoms.png';
import NL from '../images/flag64/netherlands.png';

const LanguageComponent = () => {
  const { setLanguage, getAllTranslationTexts } = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  
  const [currentLanguage, setCurrentLanguage] = useState({"language_code":"nl", "country_code":"NL", "flag":NL});
  const languages = [{"language_code":"en", "country_code":"GB", "flag":GB}, {"language_code":"nl", "country_code":"NL", "flag":NL}]

  // Retrieves all relevant import translation texts based on current language
  useEffect(() =>{
    getAllTranslationTexts(currentLanguage.language_code, currentLanguage.country_code);
  }, [currentLanguage]); 

  // Toggles language select dropdown event
  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="language-selector">   
      <div className="selected-language">
        <img className="flag-icon hover" src={currentLanguage.flag} onClick={toggleDropdown} />
      </div>
      {isOpen && (
        <ul className="language-dropdown">
          {languages.map((lang, index) => (
            lang.flag !== currentLanguage.flag ? (
              <li key={index}>
                <img className="flag-icon hover" src={lang.flag} onClick={()=>{setCurrentLanguage({"language_code":lang.language_code, "country_code":lang.country_code, "flag":lang.flag}); setLanguage({"language_code": lang.language_code, "country_code": lang.country_code}); toggleDropdown();/*window.location.reload()*/}}/>
              </li>
            ) : null
         ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageComponent;
