import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';
import { getCookie, clearQueryParams, getExpireDate} from './utilities/utils'; // Import the utility function
import { TranslationProvider } from './components/TranslationContext';
function App() {
  useEffect(() =>{ 
    //Check query params to handle factview authentication
    const queryParams = new URLSearchParams(window.location.search);
    if(queryParams.get("auth_code") && queryParams.get("client_id")){
      fetch('https://api2.factview.nl/tokens/exchange_authcode?auth_code='+queryParams.get("auth_code")+"&client_id="+queryParams.get("client_id")+"&user_id="+queryParams.get("user_id"))
      .then(
        response => { return response.json()})
      .then(data => {
        const accessExpiration = getExpireDate(10);
        const refreshExpiration = getExpireDate(60 * 24 * 30);
        document.cookie = `factview_access=${data["accessToken"]};expires=`+accessExpiration+`;path=/`;
        document.cookie = `factview_refresh=${data["refreshToken"]};expires=`+refreshExpiration+`;path=/`;
        
        clearQueryParams(["auth_code", "client_id", "user_id"]);
      })
      .catch(error => {
        console.error('Error fetching token data:', error);
      });
    }
    else{
      // Create new import session
      fetch('/session/create')
      .then(response => response.json())
      .then(data => {
        console.log(data["session_id"]);
        document.cookie = `session_id=${data["session_id"]}; path=/`;
        factview_auth();
      })
      .catch(error => {
        console.error('Error fetching session data:', error);
      });
    }
  }, []); // [] makes sure that this useEffect block only runs on the first render of the component

  const factview_auth = () => {
    // Perform the factview authentication process
    const clientId = process.env.REACT_APP_CLIENT_ID_FACTVIEW;
    fetch('https://api2.factview.nl/access_token', {
      headers: {
        Authorization: 'Bearer '+getCookie('factview_access'),
        // 'Content-Type': 'application/json',
      },
    })
    .then(response => {
      if (!response.ok) {
        if (response.status === 401 || response.status === 404) {
          // Handle token expiration or invalid token
          // Perform token refresh
          fetch('https://api2.factview.nl/refresh_token', {
            headers: {
              Authorization: 'Bearer '+getCookie('factview_refresh'),
            },
          })
          .then(response => {
            if (!response.ok) {
              if (response.status === 401|| response.status === 404) {
                // Handle token expiration or invalid token
                // Perform re-authentication
                window.location.href = 'https://monitor.factview.nl/oauth/index.php?client_id='+clientId+'&import_session='+getCookie('session_id');
              } else {
                // Handle other authorization errors
                // Display an error message to the user
              }
            } else {
              // Process the successful response
              return response.json();
            }
          }).then(responseData =>{
              fetch('https://api2.factview.nl/tokens/refresh_access?refresh_token='+responseData["refresh_token"]).then(refreshResponse => {  
                if(refreshResponse.ok){
                  return refreshResponse.json();
                }
              }).then(refreshData => { 

                updateSessionUserId(refreshData["user_id"]);

                const accessExpiration = getExpireDate(10);
                document.cookie = `factview_access=${refreshData["accessToken"]};expires=`+accessExpiration+`;path=/`;
              });
          })
          .catch(error => {
            // Handle network errors or other unexpected errors
          });
        } else {
          // Handle other authorization errors
          // Display an error message to the user
        }
      } else {
        // Process the successful response
        return response.json();
      }
    }).then(responseData =>{
      updateSessionUserId(responseData["user_id"]);
    }).catch(error => {
      // Handle network errors or other unexpected errors
    });
  }

  function updateSessionUserId(user_id){
    if(getCookie('session_id')){
      fetch('/import_session/update_user_id', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          session_id: getCookie('session_id'),
          user_id: user_id,
        }),
      }).then(refreshResponse => {  
        if(refreshResponse.ok){
          return refreshResponse.json();
        }
      }).then(refreshData => {  

      return refreshData; 
      });
    }
    else{
    }
  }

  return (
    <div className="App">
      <TranslationProvider>
        {<Header />}
        {<Body />}
        {<Footer />}
      </TranslationProvider>
    </div>
  );
}

export default App;
