import React, { createContext, useContext, useState, useEffect } from 'react';

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState([]);
  const [language, setLanguage] = useState({"language_code": 'nl', "country_code": 'NL'});

  const getAllTranslationTexts = async (language_code = language.language_code, country_code = language.country_code) => {
    try {
      const response = await fetch(`/translation/fetchAll?langCode=${language_code}&countryCode=${country_code}`);
      const data = await response.json();
      setTranslations(data);
      return data;
    } catch (error) {
      console.error('Error fetching translations:', error);
      return [];
    }
  }

   const getTranslation = (referenceCode) => {
    for(const text of translations){
      if(text.reference_code == referenceCode){
        return text.translation;
      }
    }
    return "*Translation error*";
  }

  useEffect(() => {
      getAllTranslationTexts(language.language_code, language.country_code);
    }, []);

  return (
    <TranslationContext.Provider value={{ translations, language, getTranslation, getAllTranslationTexts, setLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
}

export const useTranslations = () => {
  const context = useContext(TranslationContext);
  if (context === undefined) {
    throw new Error('useTranslations must be used within a TranslationProvider');
  }

  return context;
};
