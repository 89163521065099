import React, { useEffect, useState } from 'react';
import { getCookie, clearQueryParams, getCurrentUser, getExpireDate, } from '../utilities/utils'; // Import the utility function
import '../css/ImportComponent.css';
import DropdownComponent from './DropdownComponent';
import ListComponent from './ListComponent';
import ExactLogo from '../ExactLogo.png';
import { useTranslations  } from './TranslationContext';
// Import the core library and icon packs you need
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faChevronDown, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { executeCaseQuery } from '../utilities/import';

// Add the icons you will use to the library
library.add(faChevronDown, faXmark, faArrowLeft);

const ExactGlobeComponent = () => {
  async function getData(){

    const response = await fetch('/exactglobe/connect', {
      method: 'GET',
    })
    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData);
    } 
  }
  async function fetchData(){

    const response = await fetch('/exactglobe/fetchData', {
      method: 'GET',
    })
    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData);
    } 
  }
  // Redirect to home page event
   async function handleBackClicked(){
    window.location.href = '/app'
  }
  return (
    <div className="exactglobe-component">
      {/* <div className="back-btn-container">
        <FontAwesomeIcon className="btn-back hover" onClick={handleBackClicked} icon={faArrowLeft}/>
      </div> */}
     <button className="import-btn hover"  onClick={getData}>Fetch</button>
    </div>
  );
}

export default ExactGlobeComponent;
