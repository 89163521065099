import { checkImportExact,} from './import';

// Handles exactonline import execution on the server
export async function handleImport(row, updateStatus){
  // await checkImportExact(data[id].entiteit, data[id].year, data[id].budget_scenario, office);
  try {
    handleStatus(updateStatus);
    const response = await fetch('/exactonline/executeImport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json' 
      },
      body: JSON.stringify({
        entiteit: row.entiteit,
        year: row.year,
        budget_scenario: row.budget_scenario,
        office: row.office,
      }),
    });
    if (response.ok) {
      // const responseData = await response.json();  
      // return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

// Handles fetching status updates from the server
async function handleStatus(updateStatus){
  const response = await fetch('/exactonline/fetchUpdates');

  if (response.ok) {
    const responseData = await response.json();
    
    if(responseData){
      updateStatus(responseData);

      if(responseData.length > 0 && responseData[responseData.length-1]["status"] == 1){
        setTimeout(function(){
          handleStatus(updateStatus);
        },100);
      }
    } 
  }
}


