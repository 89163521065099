import React, { useState, useEffect, useRef } from 'react';
import '../css/DropdownComponent.css';
import { useTranslations } from './TranslationContext';

const DropdownComponent = ({ options, onSelect, defaultValue, className }) => {
  const { getTranslation, translations } = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const dropdownRef = useRef(null);
  const [defaultOption, setDefaultOption] = useState();
  const toggleDropdown = () => setIsOpen(!isOpen);

  //Handles dropdown click event
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  //Resets dropdown event
  const resetDropdown = () =>{
    setSelectedOption(defaultOption);
  }

  useEffect(() => {
    if(options.length > 0 && (options[0].type == "administration" || options[0].type == "case" || options[0].type == "dataset")){
      setDefaultOption({ label:  getTranslation("import_SelectAnOption"), value: false, type: options[0].type});
    }
    else if(options.length > 0 && options[0].type == "beginbalans"){
      setDefaultOption({label: getTranslation("import_Yes"), value: 1, type:'beginbalans'});
    }
    else if(options.length > 0 && options[0].type == "memoriaal"){
      setDefaultOption({label: getTranslation("import_No"), value: 0, type:'memoriaal'});
    }
  
    //Event listener function to close the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [options]);
  
  return (
    <div className={`custom-dropdown ${className}-dropdown`} ref={dropdownRef}>
      <div className="dropdown-header"  value={selectedOption ? selectedOption.value : null} onClick={toggleDropdown}>
        {selectedOption ?  selectedOption.label : defaultValue ? defaultValue.label : getTranslation("import_SelectAnOption")}
        <span className={`arrow ${isOpen ? 'open' : ''}`}></span>
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {options.map((option, index) => (
            <li
              key={index}
              className={`dropdown-item ${selectedOption === option ? 'selected' : ''}`}
              onClick={() => handleOptionClick(option)}
              value={option.value}
            >
              {option.label} { option.value ? (options[0].type == "administration" || options[0].type == "case" || options[0].type == "dataset") ? null : null : null}
            </li>
          ))}
        </ul>
      )}
      <span className="reset-dropdown-btn" onClick={() => resetDropdown()}></span>
    </div>
  );
}

export default DropdownComponent;
