import React, { useState, useEffect, useRef } from 'react';
import { deleteCookie } from '../utilities/utils';

const ProfileComponent = () => {

  useEffect(() =>{
  }, []); 

  async function logout() {
    fetch('https://monitor.factview.nl/?logout=1', {
      method: 'GET',
      credentials: 'include', // Send cookies with the request if needed
    })
      .then(response => {
        if (response.ok) {
          console.log('Logout successful');
        } else {
          throw new Error('Logout failed');
        }
      })
      .catch(error => console.error('Error during logout:', error));
    await deleteCookie("factview_access");
    await deleteCookie("factview_refresh"); 

    // window.location.href = 'https://importmodule.factview.nl/app';
  }
  return (
    <div className="profile-component">   
     <button onClick={logout}>uitloggen</button> 
    </div>
  );
};

export default ProfileComponent;
