import React, { useState, useEffect, useRef } from 'react';
import { getCookie, } from '../utilities/utils'; // Import the utility function
import { clearExistingScheduledImports, } from '../utilities/import'; // Import the utility function
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// Import the core library and icon packs you need
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Add the icons you will use to the library
library.add(faXmark);
function ScheduleComponent({importData, onCancel, importType}) {
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTime, setSelectedTime] = useState(false);
  const [lastAction, setLastAction] = useState(null);
  const lastClicked = useRef(null);
  // useEffect to execute side effect after selectedDates update
  useEffect(() => {
    setTimeout(function(){
      handleHighlightCss(selectedDates);
      if(selectedDates.length == 0){
        if(!lastClicked.current || !lastClicked.current.classList.contains("current-highlighted-date")){
          toggleTimeSelect(false); 
        }
      }
    },1);
   
    const datePicker = document.querySelector('.schedule-component');
    const handleClick = (event) =>{
      const elementClicked = event.target;
      lastClicked.current = elementClicked;
      if(elementClicked.classList.contains("react-datepicker__time-list-item")){
        handleTimeClicked(elementClicked);
      }
      setLastAction(elementClicked);
    }

    // Add event listeners
    if (datePicker) {
      datePicker.addEventListener('click', handleClick);
    }
    if(selectedTime){
      handleTimeChange(selectedTime);
    }
  }, [selectedDates, selectedTime, lastAction]); // Dependency array ensures this effect runs after selectedDates changes
  
  useEffect(()=>{
    handleScheduledImports(importData);
  }, []);

  async function handleTimeClicked(element){
    setSelectedTime(element.innerHTML);
  }

  async function handleScheduledImports(importData){
    const scheduledImports =  await fetchScheduledImports(importData); 
    var dates = [...selectedDates];
    for(const imp of scheduledImports){
      var scheduledTime = new Date(imp.scheduled_time);
      dates.push(scheduledTime);
    }
    
    if(dates.length > 0){
      setSelectedDates(dates);
      await toggleTimeSelect(true);
      setSelectedTime(await getTimeString(dates[0]));
    } 
  }

  async function fetchScheduledImports(importData){
    const response = await fetch('/scheduler/getScheduledImportBy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        import_type: importData.import_type,
        entiteit: importData.entiteit,
        year: importData.year,
        office: importData.office,
        budget_scenario: importData.budget_scenario,
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  }

  // Function to handle date selection
  async function handleDateChange(date){ 
    if(lastClicked.current.classList.contains("react-datepicker__day")){
      const dates = [...selectedDates];
      const dateIndex = dates.findIndex(
        (d) => d.toDateString() === date.toDateString()
      );

      if (dateIndex >= 0) {
        dates.splice(dateIndex, 1);
      } else {
        dates.push(date);
      }    
      if(dates.length == 0){
        setSelectedTime(false);
        await toggleTimeSelect(false);
      }
      else{
        await toggleTimeSelect(true);
      }

      handleHighlightCss(dates);
      setSelectedDates(dates);
    }
  }

  async function toggleTimeSelect(bool){
    var timeList = document.querySelector(".react-datepicker__time-list");
    if(timeList){
      var timeEls = timeList.childNodes;
      for(const timeEl of timeEls){
        if(!bool){
          timeEl.classList.add("react-datepicker__time-list-item--disabled");
          timeEl.setAttribute(`aria-disabled`, true);
        }else{
          var timeString = timeEl.innerHTML; 
          const [time, period] = timeString.split(" ");
          const [hours, minutes] = time.split(":");
          if(period == "AM" &&  hours > 3  && hours <= 5){

          }
          else{
            timeEl.classList.remove("react-datepicker__time-list-item--disabled");
            timeEl.setAttribute(`aria-disabled`, false);
          }
        }
      }
    }
  }

  async function handleTimeChange(time){
    var highlightedEl = document.querySelector(".highlighted-time");
    if(highlightedEl){
      highlightedEl.classList.remove("highlighted-time");
    }

    var timeEls = document.querySelector(".react-datepicker__time-list").childNodes;
    for(const timeEl of timeEls){
      if(timeEl.innerHTML == time){ 
        const isDisabled = timeEl.getAttribute("aria-disabled");
        if(isDisabled === "false"){ 
          timeEl.classList.add("highlighted-time");
        }
      }
    }
  }
  
  async function handleHighlightCss(dates){
    var existArray = [];
    const currentHighlight = document.querySelector(".current-highlighted-date");
    
    if(currentHighlight){
      currentHighlight.classList.remove(".current-highlighted");
    }

    //Loop om alle toevoegingen te behandelen
    for(const date of dates){
      var ariaLabel = await formatAriaLabel(date);
      const el = document.querySelector(`[aria-label*="${ariaLabel}"]`);
      existArray.push(el);

      if(el && !el.classList.contains("highlighted-date")){
        el.classList.add("highlighted-date");
        el.classList.add("current-highlighted-date");
      }
    }

    //Loop om alle verwijderingen te behandelen
    var currentlyHighlighted = document.querySelectorAll(".highlighted-date");
    
    for(const highlighted of currentlyHighlighted){
      var exists = false;
      
      for(const exist of existArray){
        if(highlighted && exist && highlighted.getAttribute("aria-label") == exist.getAttribute("aria-label")){
          exists = true;
        }
      }
      if(!exists){
        highlighted.classList.remove("highlighted-date");
      }
    }
  }

  // Function to exclude times from 3 AM to 5 AM
  const filterTimes = (time) => {
    var currentEl = document.querySelector(".current-highlighted-date");
 
    if(currentEl){
      // Extract the date string from aria-label
      const dateString = currentEl.getAttribute("aria-label").replace('Choose ', '');
      const currentTime = parseAriaLabelToDate(dateString);
      const currentDate = new Date();
      const expireDate = new Date(getCookie("exact_refresh_expiration")); 
      
      if(time.getHours() > 3 && time.getHours() <=6){
        return false;
      }
      else if (currentTime.getFullYear() === currentDate.getFullYear() && currentTime.getMonth() === currentDate.getMonth() && currentTime.getDate() === currentDate.getDate()) {
        return (time.getHours()>currentDate.getHours()|| (time.getHours() == currentDate.getHours() && time.getMinutes() > currentDate.getMinutes()))
      }
      else if(currentTime.getFullYear() === expireDate.getFullYear() && currentTime.getMonth() === expireDate.getMonth() && currentTime.getDate() === expireDate.getDate()){
        return  (time.getHours()<expireDate.getHours()|| (time.getHours() == expireDate.getHours() && time.getMinutes() < expireDate.getMinutes()))
      }
    }  

    return true;
  };

  async function formatAriaLabel(date) {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDateString = date.toLocaleDateString('en-US', options);
  
    // Extract day of the week and numeric day
    const dayOfWeek = formattedDateString.split(',')[0];
    let dayOfMonth = formattedDateString.split(',')[1].trim();
    const year = formattedDateString.split(',')[2].trim();

    // Add ordinal indicator to day (e.g., "1st", "2nd", "3rd", "4th", etc.)
    const ordinalIndicator = await getOrdinalIndicator(dayOfMonth);
    dayOfMonth = `${dayOfMonth}${ordinalIndicator}`;
  
    return `Choose ${dayOfWeek}, ${dayOfMonth}, ${year}`;
  }

  // Converts the calendars aria-label to Date variable
  function parseAriaLabelToDate(dateString) {
    // Step 1: Remove the day of the week and ordinal indicators
    const cleanedDateString = dateString
        .replace(/^[A-Za-z]+,\s*/, '')  // Remove the day of the week
        .replace(/(\d+)(st|nd|rd|th)/, '$1');  // Remove ordinal indicators

    // Step 2: Parse the cleaned string into a Date object
    const date = new Date(cleanedDateString);

    // Step 3: Check if the parsed date is valid
    if (isNaN(date)) {
        throw new Error("Invalid date format");
    }

    return date;
  }

  // Get date ordinal indicators st/nd/rd/th
  async function getOrdinalIndicator(dayOfMonthString) {
    // Regular expression to match one or more digits
    const numericRegex = /\d+/;
    
    // Extract numeric part from the string
    const numericPart = dayOfMonthString.match(numericRegex);

    // Convert matched numeric part to integer
    const number = parseInt(numericPart[0], 10);
    if (number === 0) return ""; // Handle 0 separately if needed

    // Handle special cases for 11, 12, 13
    if (number % 100 >= 11 && number % 100 <= 13) {
      return "th";
    }
  
    // Handle all other cases
    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  // Date to string hr:min period
  async function getTimeString(time){
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var period = (hours <= 12 && minutes == 0)?"AM":(hours < 12)?"AM":"PM";
    
    hours = (hours > 12) ? hours - 12 : hours;
    minutes = (minutes == 0 ) ? "00":minutes;
    var timeString = hours +":"+minutes+" "+period;

    return timeString;
  }
  
  async function submitClicked(){
    await clearExistingScheduledImports({"import_type": importType, "entiteit": importData["entiteit"], "year": importData["year"],"office": importData["office"], "budget_scenario": importData["budget_scenario"]})
    var [time, period] = selectedTime.split(" ");
    var [hours, minutes] = time.split(":");
    hours = (period == "AM" && hours != 12) ? parseInt(hours) : parseInt(hours) + 12;
    var scheduledImports = [];

    for(const date of selectedDates){
      date.setHours(hours);
      date.setMinutes(parseInt(minutes));
      await postScheduledImport({"import_type": importType, "entiteit": importData["entiteit"], "year": importData["year"],"office": importData["office"], "budget_scenario": importData["budget_scenario"], "scheduled_time": date});
    }
    window.location.href = window.location.href;
  }

  // Posts scheduled import based on the calendar inputs
  async function postScheduledImport(body){
    try{
      const response = await fetch('/scheduler/createScheduledImport', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const responseData = await response.json();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function reloadSchedule(){
    try{
      const response = await fetch('/scheduler/reloadSchedule', {
        method: 'GET',
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  return (
    <div className="schedule-component">
      <div className="schedule-header">
        <div className={`schedule-close-window hover`} onClick={onCancel}><FontAwesomeIcon className="close-icon" icon={faXmark}/></div>
        <p>Import plannen</p>
      </div>
      <div className="custom-date-time-picker">
        <DatePicker
          selected={null}
          onChange={handleDateChange}
          inline
          dateFormat="Pp"
          showTimeSelect  
          multiple
          className="custom-date-input"
          minDate={new Date()}
          maxDate={getCookie("exact_refresh_expiration")}
          filterTime={filterTimes}
          popperPlacement="bottom-start"
        />
      </div>    
      <button className={`schedule-btn ${(selectedDates.length > 0 && selectedTime) ? "hover" : "disabled"}`} onClick={()=>{submitClicked(); onCancel();}}>Submit</button>
      <button className="schedule-btn hover" onClick={onCancel}>Cancel</button>
      {/* <button className="schedule-btn reload-schedule hover" onClick={reloadSchedule}>Reload</button> */}
    </div>
  );
}

export default ScheduleComponent;