// cookieUtils.ts
export var translations = [];

export async function getAllTranslationTexts(langCode="nl", countryCode="NL"){
  var response = await fetch('/translation/fetchAll?langCode='+langCode+'&countryCode='+countryCode, {
  });

  const data = await response.json();
  translations = data;

  return data;
}

export async function getTranslation(referenceCode){
  for(const text of translations){
    if(text.referenceCode = referenceCode){
      return await text.translation;
    }
  }
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift() || null;
  }
  return null;
}

export function deleteCookie(name) {
  // Set the cookie with the same name and path, and an expiration date in the past
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}

export function clearQueryParams(paramsToClear){
  const url = new URL(window.location);
  paramsToClear.forEach(param => url.searchParams.delete(param));
  window.history.replaceState({}, '', url);
}

export async function getCurrentUser(){
  const token = getCookie("factview_access");
  if(token){
    try {
      const response = await fetch('/app_user/getUserByAccess?access='+token);
      if (response.ok) {
        const responseData = await response.json();
        return responseData
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
}

export async function splitArrayIntoChunks(array, chunkSize){
  const result = [];
  
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  
  return result;
}

export async function createReplaceQueryFromArray(array, tableName){
  var keyString = false; 
  var valueString = "";
  for(const el of array){  
    var keys = "";
    var values = "(";
    for(const [key, value] of Object.entries(el)){
      if(!keyString){
        keys += "`"+key+"`,";
      } 
      values += "'" + String(value).replace("'", "''")+"',";
    }
    if(!keyString){
      keyString = keys.slice(0, -1);
    }
    valueString += values.slice(0, -1) + "),";
  }

  var query = "REPLACE INTO `" + tableName + "` (" + keyString + ") VALUES " + valueString.slice(0, -1) + "; \n";
  return query;
}

export function getExpireDate(minutes){
  var expiration = new Date();
  var time = expiration.getTime();
  var expirationTime = time + minutes * 60 * 1000; 
  expiration.setTime(expirationTime);
  expiration.toGMTString();
  return expiration;
}

export async function formatDate(date){
  // Format the Date object to the desired format (Y-m-d)
  var year = date.getUTCFullYear();
  var month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
  var day = ('0' + date.getUTCDate()).slice(-2);

  var formattedDate = year + '-' + month + '-' + day;
  return formattedDate;
}